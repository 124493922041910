import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Industries from "../components/Industries"
import Specialties from "../components/Specialties"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"
import Contact from "../components/Contact"
import Benefits from "../components/Benefits"
export default ({ data, location }) => {

  const {
    allStrapiProjects: { nodes: projects },
    allStrapiBlogs: { nodes: blogs },
    allStrapiIndustries: { nodes: industries },
  } = data
  return (
    <Layout>
      <SEO descripton={'This is home page'} pathname={location.pathname}/>
      <Hero isMainPage />
      <Services />
      <Specialties/>
      <Benefits/>
      <Industries industries={industries} />
      <Contact isSubTitle formName="Contact"/>
      {/*<Projects projects={projects} title={"featured projects"} showLink />*/}
      {/*<Blogs blogs={blogs} title={"Blog"} showLInk />*/}
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiProjects(sort: {fields: strapiId, order: DESC}, filter: { featured: { eq: true } }) {
      nodes {
        description
        id
        featured
        stack {
          id
          title
        }
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiBlogs(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        slug
        date(formatString: "MMMM Do, YYYY")   
        description
        category
        id
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiIndustries(filter: {isVisible: {eq: true}}, sort: {fields: strapiId, order: DESC}) {
      nodes {
        id
        shortDescription {
          name
          id
        }
        slug
        title
        subTitle
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
