import React from "react"
import Title from "./Title"
import specialties from "../constants/specialties"

const Specialties = () => {
    return (
        <section id={'specialties'} className={"section"}>
            <Title title={"Specialties"} />
            <div className="section-center services-center">
                <div className='specialties'>
                    <div className='specialty specialty-text1'>
                        <h4 className='service-description-heading'>Any technology. Any skill. Any time.</h4>
                        You manage the projects, and our world-class full-time teams of developers are yours to bring it to reality.
                    </div>
                    {specialties.map(specialty => {
                        const { id, icon, title } = specialty
                        return (
                            <div key={`specialty-${id}`} className='specialty'>
                                <img alt={title} src={icon} loading='lazy'></img>
                            </div>
                        )
                    })}
                    <div className='specialty specialty-text2'>
                        <h4 className='service-description-heading'>Adding value from day one.</h4>
                        Our teams onboard quickly and start delivering results from the first day.
                    </div>
                </div>
            </div>
    </section>
)};

export default Specialties
