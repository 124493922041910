import software_dev_service from "../assets/software_dev_service.svg"
import design_dev_service from "../assets/design_service.svg"
import recruitment_service from "../assets/recruitment_service.svg"
import consaltansy_service from "../assets/consaltansy_service.svg"

export default [
  {
    id: 1,
    icon: software_dev_service,
    title: "Web and app development",
    text: `Software engineering is at the heart of what we do. Our engineers bring years of strategic planning and technical expertise to deliver results. Let us manage all stages of the development cycle—from brainstorming to post-delivery support.`,
  },
  {
    id: 2,
    icon: recruitment_service,
    title: "Recruitment",
    text: `Ramp up your team on-demand. Build your team from scratch. We can help you hire the best talents from the market and scale your company like never before. With a team of professional recruiters, we make the process very simple.`,
  },
  {
    id: 3,
    icon: design_dev_service,
    title: "Design and strategy",
    text: `We use a highly structured design process that’s been honed through years of experience. 
    It allows us to prototype complex ideas quickly and accurately that can be easily transformed into fully functional solutions and scalable systems.`,
  },
  {
    id: 4,
    icon: consaltansy_service,
    title: "Consultancy",
    text: `We bring our industry experts to solve your unique needs. Backed by a team of remarkable specialists we bring global and local knowledge to each engagement in order to solve your challenges quickly and with the best efficiency.`,
  },
]
