import React from "react"
import { BiTrendingUp } from "react-icons/bi"
import { BsPersonCheckFill } from "react-icons/bs"
import { FaFileContract } from "react-icons/fa"
import { RiTeamFill } from "react-icons/ri"
import { VscServerProcess } from "react-icons/vsc"
export default [
  {
    id: 1,
    icon: <RiTeamFill className="service-icon" />,
    title: "Dedicated Development Teams",
    text: `Get full-time, scalable teams of trusted developers.`,
  },
  {
    id: 2,
    icon: <BsPersonCheckFill className="service-icon" />,
    title: "World Class Specialists",
    text: `Everything we do has one common ingredient: some of the best experts in the world.`,
  },
  {
    id: 3,
    icon: <FaFileContract className="service-icon" />,
    title: "Flexible Contract",
    text: `Scalable contracts with a simple pay as you go billing.`,
  },
  {
    id: 4,
    icon: <VscServerProcess className="service-icon" />,
    title: "Process Transparency",
    text: `We will provide full transparency on our process so you can follow it in real-time.`,
  
  }, 
 {
    id: 5,
    icon: <BiTrendingUp  className="service-icon" />,
    title: "Brand Promotion",
    text: `At the top of our priorities is promoting our customers brand as it's our own.`,
  }, 
]
