import ios_icon from "../assets/ios-icon.svg"
import android_icon from "../assets/android-icon.svg"
import web_icon from "../assets/web-icon.png"
import blockchain_icon from "../assets/blockchain-icon.png"
import vr_icon from "../assets/vr-icon.png"
import m_l_icon from "../assets/machine-learning-icon.png"
import cloud_icon from "../assets/cloud-icon.png"

export default [
  {
    id: 1,
    icon: web_icon,
    title: "WEB",
  },
  {
    id: 2,
    icon: android_icon,
    title: "Android",
  },
  {
    id: 3,
    icon: ios_icon,
    title: "IOS",
  },
  {
    id: 4,
    icon: cloud_icon,
    title: "Cloud",
  },
  {
    id: 5,
    icon: vr_icon,
    title: "AR/VR",
  },
  {
    id: 6,
    icon: blockchain_icon,
    title: "Blockchain",
  },
  {
    id: 7,
    icon: m_l_icon,
    title: "Machine Learning",
  },
]
