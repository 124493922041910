import React from "react"

const Service = ({ id, title, text, icon }) => {
  return (
    <article id={id} key={id} className={"service"}>
      <div className={'services-icon-wrapper'}>
        <img src={icon} alt={title} loading='lazy'></img>
        <div className='services-icon-bg'></div>
      </div>
      <div className='service-description'>
        <h3 className='service-description-heading'>{title}</h3>
        <div>{text}</div>
        <a className="btn center-btn" href="/#contact">Learn more</a>
      </div>
    </article>
  )
}

export default Service
