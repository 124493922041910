import React from "react"
import Title from "./Title"
import { FcCheckmark } from "react-icons/fc"
import { Link } from "gatsby"
import Image from "gatsby-image"

const Industries = ({ industries, showLink }) => {
  return (
    <section id={'industries'} className={"section jobs"}>
      <Title title={"Industries we serve"} />
      <div className="jobs-center">
        {industries.map((industry, index) => {
          return (
            <article className="job-info" key={index}>
              <div className="job-image">
                <Image fluid={industry.image.childImageSharp.fluid} className={"job-img"} />
              </div>
              <div className="job-content">
                <h3>{industry.title}</h3>
                <p>{industry.subTitle || 'Need to add'}</p>
                {industry.shortDescription.map(item => (
                  <div key={item.id} className={"job-desc"}>
                    <FcCheckmark className={"job-icon"}></FcCheckmark>
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </article>
          )
        })}
      </div>
      <div className="job-footer">
        <h3>And many more</h3>
        <p>If your industry is not listed above, no worries!</p>
        <p><Link to={'#contact'} className={'link'}>Get in touch</Link> with us and let’s understand your needs.</p>
      </div>
    </section>
  )
}

export default Industries
