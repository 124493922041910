import React from "react"
import services from "../constants/benefits"
import Title from "./Title"

const Benefits = () => {
  return (
    <section id={'recruitment-benefits'} className={"section bg-grey bg-grey--shadow "}>
      <Title title={"Why Tech Space"} />
      <div className="benefits-center--scrollable services-center">
        {services.map(service => {
          const { id, icon, title, text } = service
          return (
            <article key={id} className={"benefits-item"}>
              <div className={'recruitment-benefits-icon-wrapper'}>{icon}</div>
              <h4>{title}</h4>
              <div className="underline"></div>
              <p>{text}</p>
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Benefits
