import React, { useEffect, useRef } from "react"
import Title from "./Title"
import services from "../constants/services"
import Service from "../components/Service"
import useOnScreen from "../hooks/on-screen"

const Services = () => {

  const ref = useRef()
  let isVisible = useOnScreen(ref);


  return (
    <section id={'services'} className={"section"} ref={ref}>
      <div className={'section-center services-center bg-grey'}>
        <h3 className={'services-center-heading services-center-heading--space'}>We will help you <span className={isVisible ? 'underline--animated' : ''}>build, measure and optimize your online business</span> by understanding your needs, building your online strategy and developing high-performance solutions that <strong>deliver results</strong>.</h3>
      </div>
      <Title title={"Services"} />
      <div className="section-center services-center">
        {services.map(service => {
          const { id, icon, title, text, hashtags } = service;
          return (
            <Service id={`service-${id}`} icon={icon} title={title} text={text}/>
          )
        })}
      </div>
    </section>
  )
}

export default Services
